.overlay-container {
  overflow: hidden;
  position: absolute;

  bottom: 0;
  right: 0;
  height: auto;
  z-index: 1000;
  opacity: 0.85;
  &.hide {
    display: none;
  }
  &.show {
    display: block;
  }
}

.overlay-widget {
  /*position: absolute;*/
  margin: auto;
  /*left: 0;*/
  right: 0;
  text-align: center;
  bottom: 0;
  min-width: 400px;
  /*width: 600px;*/
  /*background-color: pink;*/

  &.hide {
    /*display: none;*/
    transform: translateY(1000px);
    transition: transform 1s;
  }

  &.show {
    /*display: none;*/
    transform: translateY(0);
    transition: transform 0.5s;
  }
}

.overlay-close-button-container {
  text-align: right;
}

.overlay-close-button {
  background-color: var(--ion-background-color, #fff);
  margin-bottom: -5px;
  border-radius: 5px 5px 0px 0px;
}
