.location-container {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  height: auto;
  z-index: 1000;
  opacity: 0.85;
  &.hide {
    display: none;
  }
}

.locate-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin-top: -8px; /* Half the height */
  margin-left: -8px; /* Half the width */
  color: black;

  &.hide {
    display: none;
  }
}

.add-locate-widget {
  /*position: absolute;*/
  margin: auto;
  /*left: 0;*/
  right: 0;
  text-align: center;
  bottom: 0;
  /*width: 600px;*/
  /*background-color: pink;*/

  &.hide {
    /*display: none;*/
    transform: translateY(1000px);
    transition: transform 1s;
  }

  &.show {
    /*display: none;*/
    transform: translateY(0);
    transition: transform 0.5s;
  }
}
/*.close-button {
  position: absolute;
  right: 0;
  top: 5px;
}*/

.location-list-container {
  max-height: 300px;
  overflow: scroll !important;
}

.location-details {
  opacity: 0.9;
}

.add-location-close-button-container {
  text-align: right;
}

.add-location-close-button {
  background-color: var(--ion-background-color, #fff);
  margin-bottom: -5px;
  border-radius: 5px 5px 0px 0px;
}
