.indicator {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 16px;
  height: 32px;
  padding: 0 8px;
}

@keyframes label-animation {
  0% {
    max-width: 0;
    opacity: 0;
  }
  10% {
    max-width: 128px;
    opacity: 1;
  }
  90% {
    max-width: 128px;
    opacity: 1;
  }
  100% {
    max-width: 0;
    opacity: 0;
  }
}

.label {
  &.animate {
    animation-name: label-animation;
    animation-duration: 5000ms;
    animation-timing-function: ease-in-out;
  }

  span {
    padding-right: 8px;
  }

  max-width: 0;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  transition: background 500ms ease-in-out;
}

.wifi {
  background: #388e3c;
}

.cellular {
  background: #f57c00;
}

.none {
  background: #d32f2f;
}

.unknown {
  background: #616161;
}

ion-alert.network-indicator-alert {
  --backdrop-opacity: 0.85;
}

.network-indicator-alert .alert-head {
  text-align: center;
}

.network-indicator-alert .alert-wrapper {
  border-radius: 25px;
}

.network-indicator-alert .alert-button-group {
  display: flex;
  justify-content: space-around;
}
