.help {
  img {
    &.drop-shadow {
      filter: drop-shadow(0 0 2px black);
    }
    height: 64px;
    width: auto;
  }
  tr {
    td:first-child {
      text-align: center;
      width: 72px;
    }
    height: 80px;
  }

  margin-top: 40px;
}

.cesium-navigation-help-details {
  color: var(--ion-color-dark);
}

.popover {
  --width: 320px;
}

.scene-mode-icon {
  &.button {
    height: 36px;
    width: 20px;
  }
  height: 32px;
  width: 32px;
}

.scene-mode-icon {
  &:not(.button) {
    margin: 0 16px;
  }
  fill: var(--ion-color-dark);
}

.help-swiper {
  .swiper-pagination {
    .swiper-pagination-bullet {
      background: var(--ion-color-primary);
      border-radius: 4px;
      font-size: 14px;
      height: auto;
      padding: 8px;
      text-align: center;
      transition: all 150ms;
      width: auto;
    }

    bottom: unset !important;
    top: 8px !important;
  }
}
