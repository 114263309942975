$panel-height: 30vh;

.graph-panel-statistics {
  position: absolute;
  bottom: 0;
  height: $panel-height;
  min-height: 300px;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100vw;
}

.graph-panel-list {
  position: absolute;
  bottom: max(30vh, 300px);
  width: 100vw;
  background-color: rgb(0, 0, 0);
  opacity: 0.85;
  text-align: center;
}

#graph-panel-click-trigger {
  margin: 0.5rem 10% 0 10%;
  width: auto;
  display: inline-block;
  background-color: black;
  color: white;
}

.graph-panel-close-button {
  position: absolute;
  transform: scale(0.85);
  right: 10%;
  z-index: 10;
  color: aliceblue;
  border-radius: 1rem;
  cursor: pointer;
}
