@use 'sass:math';

$raster-menu-bottom: 5rem;
$icon-size: 6rem;
$card-top-margin: 0.5rem;
$card-bottom-margin: 0.5rem;
$card-header-vertical-padding: 0.5rem;
$selected-bar-height: 0rem;
$header-height: 4.5rem;
$title-font-size: 1rem;
$subtitle-font-size: 0.8rem;
$arrow-size: 2rem;
$raster-menu-height: $icon-size + $card-top-margin + $card-bottom-margin +
  $selected-bar-height + $header-height;
$chevron-vertical-position: math.div($raster-menu-height - $arrow-size, 2) +
  $raster-menu-bottom;

//.raster-menu-scroll .swiper-wrapper {
//justify-content: center;
//}

.raster-menu-scroll {
  text-align: center;
  white-space: break-spaces;
  position: absolute;
  bottom: $raster-menu-bottom;
  width: 100%;
  height: $raster-menu-height;
  //background: rgba(var(--ion-color-light-rgb), 0.85);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));

  ion-card {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0;
    margin-top: 0.5rem;
    width: 7rem;
  }

  ion-card-header {
    padding-inline: 0;
    padding: $card-header-vertical-padding 0;
    height: $header-height;
  }

  ion-card-title {
    font-size: $title-font-size;
    --font-color: var(--ion-text-color);
    color: white;
  }

  ion-card-subtitle {
    font-size: $subtitle-font-size;
    color: grey;
  }
}

.raster-icon {
  height: $icon-size;
  width: $icon-size;
}

.selected-opt {
  div {
    background-color: var(--ion-color-primary);
    height: $selected-bar-height;
    width: 100%;
  }

  ion-card-title {
    font-weight: bolder;
    color: var(--ion-color-primary);
  }
}

.unselected-opt {
  div {
    height: $selected-bar-height;
    width: 100%;
  }

  ion-card-title {
    font-weight: normal;
  }
}

.raster-menu-backdrop {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100vh;
}

.raster-menu-close-button {
  position: absolute;
  bottom: 3rem;
  z-index: 10;
  left: 50%;
  //background-color: grey;
  background-image: linear-gradient(0deg, black, transparent);
  border-radius: 1rem;
  color: whitesmoke;
  transform: translate(-50%, -50%);
}

.raster-menu-loading {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--ion-color-light);
  opacity: 0.5;
  z-index: 9999;

  p {
    display: block;
    margin: auto;
    text-align: center;
    width: 50%;
    padding: 40vh 0;
    font-size: 2rem;
  }
}

/*
@media (prefers-color-scheme: light) {
  .raster-menu-scroll {
    background: rgba(var(--ion-color-light-rgb), 0.25);
  }
}
*/
