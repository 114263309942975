$transition: all 150ms;

@media screen and (max-width: 318px) {
  .hidden {
    transform: translateY(calc(-154px - env(safe-area-inset-top)));
  }
}

@media screen and (min-width: 318px) and (max-width: 648px) {
  .hidden {
    transform: translateY(calc(-106px - env(safe-area-inset-top)));
  }
}

@media screen and (min-width: 648px) {
  .hidden {
    transform: translateY(calc(-58px - env(safe-area-inset-top)));
  }
}

/*
.ios {
  .age-play-controls-container:not(.buttons:not(.hidden)) {
    padding-top: env(safe-area-inset-top);
  }
}
*/

.ios {
  .top-buttons-container:not(.buttons:not(.hidden)) {
    padding-top: env(safe-area-inset-top);
  }
}

.buttons {
  margin: 8px;
}

.age-play-controls-container {
  &:not(.buttons) {
    background-color: var(--ion-color-dark-contrast);
  }
  ion-item {
    &::part(native) {
      height: 100%;
    }
    flex-grow: 1;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: $transition;
}

.top-buttons-container {
  &:not(.buttons) {
    background-color: var(--ion-color-dark-contrast);
  }
  ion-item {
    &::part(native) {
      height: 100%;
    }
    flex-grow: 1;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: $transition;
}

.seek-buttons {
  > div {
    margin-left: auto;
  }
  ion-button {
    &::part(native) {
      padding: 0;
    }
    height: 40px;
    width: 40px;
    margin: 0;
  }
  ion-icon {
    font-size: 24px;
  }
  --padding-start: 4px;
  --padding-end: 4px;
  --ion-safe-area-left: 0;
  --ion-safe-area-right: 0;
  //width: 228px;
}

.slider {
  ion-button {
    &::part(native) {
      padding: 0;
    }
    height: 40px;
    width: 40px;
  }
  --inner-padding-start: 8px;
  --inner-padding-end: 8px;
  --ion-safe-area-left: 0;
  flex-grow: 100;
  width: min(100vw, 320px);
}

.time {
  align-items: center;
  color: white;
  display: flex;
  font-size: 28px;
  padding: 8px;
  text-shadow: 1px 1px 8px #000000, 1px 1px 8px #000000, 1px 1px 8px #000000;
}

.time-input {
  ion-input {
    input {
      padding-right: 8px !important;
    }
    text-align: right;
  }
  --padding-start: 16px;
  --padding-end: 16px;
  --ion-safe-area-right: 0;
  min-height: 48px;
  width: 100px;
}

#play-pause-icon {
  --ionicon-stroke-width: 64px;
  font-size: 30px;
}
