.about-page-icon {
  margin-top: 1rem;
  text-align: center;
  width: 100%;

  img {
    margin: 0 auto;
  }
}

.about-page-intro-frame {
  margin: 0 0.5rem;
  //border-style: solid;
  //border-width: 0.1rem;
  //border-color: var(--ion-color-dark);
  //border-radius: 0.5rem;
  padding: 0 0.5rem;
  margin-bottom: 40px;
}

.about-page-intro-text {
  text-align: left;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.about-page-clickable-info {
  height: 1rem;
  margin: 0 0 0.6rem 0;
}

.about-page-copyright {
  //position: fixed;
  //bottom: 20px;
  //right: 5px;
  padding-bottom: 20px;
  padding-right: 15px;
  text-align: right;
  font-style: italic;
  font-size: small;
}
