.network-indicator {
  position: fixed;
  bottom: calc(var(--ion-safe-area-bottom) + 10px);
  right: 10px;
}

/* The animation code */
@keyframes outline-animation {
  0% {
    outline-color: rgba(0, 0, 0, 0);
  }
  50% {
    outline-color: var(--ion-color-primary);
  }
  100% {
    outline-color: rgba(0, 0, 0, 0);
  }
}

/* The element to apply the animation to */
.outline-animation {
  animation-name: outline-animation;
  animation-delay: 600ms;
  animation-duration: 300ms;
  animation-iteration-count: 2;
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.round-button {
  &::part(native) {
    padding: 0;
  }
  ion-icon {
    color: var(--ion-color-dark);
    font-size: 24px;
  }
  svg {
    fill: var(--ion-color-dark);
  }
  --background: var(--ion-color-light);
  --border-radius: 20px;
  margin: 4px;
  height: 40px;
  width: 40px;
}

.toolbar-top {
  top: env(safe-area-inset-top);
  left: 0;
  overflow: hidden;
  position: absolute;
  height: auto;
  width: 100%;
}

.toolbar-bottom {
  ion-fab-list {
    ion-fab-button {
      ion-icon {
        font-size: 30px;
      }
    }
  }
}

.test {
  ion-icon {
    color: white;
  }
}

.screenshot-loading .loading-wrapper {
  border-radius: 15px;
}
